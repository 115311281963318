import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
// import img from '../assets/img2.jpg'
export default function About() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row className="px-xs-0 px-sm-1 px-md-1 px-lg-3 px-xl-5">
                    <Col
                        md={6}
                        lg={8}
                        className="home-about-description  px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <h1>
                            <span> ABOUT ME:</span>
                        </h1>
                        <p className="home-about-body">
                            I am passionate about programming and the idea of
                            creating something out of a few lines of code still
                            fascinates me.
                            <br />
                            <br />
                            Most of the time I keep playing with codes and see
                            where I end up.
                            <br />
                            <br />
                            If I am not coding I am watching Youtubers code.
                        </p>
                    </Col>
                    {/* <Col md={3} className="myAvtar">
                        <img src={img} className="img-fluid" alt="avatar" />
                    </Col> */}
                </Row>
            </Container>
        </Container>
    )
}

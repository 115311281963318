import './App.css'
import Home from './components/Home'
import NavBar from './components/Navbar'
import 'bootstrap/dist/css/bootstrap.min.css'
import About from './components/About'
import Projects from './components/Projects'
import Footer from './components/Footer'

import './App.css'

const gitUrl = 'https://github.com/ferozalizada'

function App() {
    return (
        <div className="App">
            <NavBar gitUrl={gitUrl}></NavBar>
            <Home
                staticText="FEROZUDDIN ALIZADA"
                inputString={['SOFTWARE DEVELOPER', 'DESIGNER', 'CREATOR']}
            />

            <About></About>
            <Projects></Projects>
            <Footer />
        </div>
    )
}

export default App

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ProjectCard from './ProjectCard'
// import Particle from "../Particle";

import driveAllAuto from '../assets/driveallauto.png'
import nooble from '../assets/nooble.png'
import memoar from '../assets/memoar.png'
import img4 from '../assets/img4.jpg'
// import img2 from '../assets/img2.jpg'
import random from '../assets/random.jpg'
import musiq from '../assets/musiq.png'
import noteocean from '../assets/noteocean.png'
function Projects() {
    return (
        <Container fluid id="projects" className="project-section">
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">Recent Projects </strong>
                </h1>
                <p style={{ color: 'white' }}>Some of my recent projects</p>
                <Row
                    className="px-xs-0 px-sm-1 px-md-1 px-lg-3 px-xl-5"
                    style={{ justifyContent: 'center', paddingBottom: '10px' }}
                >
                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={driveAllAuto}
                            title="Drive All Auto"
                            description="A basic landing page for a transportation company made with react."
                            link="https://www.driveallauto.ca"
                        />
                    </Col>

                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={nooble}
                            title="Nooble"
                            description="A Boolean and Vector Model System (VSM) based search engine. Capable of searching the uOttawa courses and Reuters documents."
                            // https://github.com/ferozalizada/Nooble-Search-Engine
                            link="https://nooble.herokuapp.com/"
                        />
                    </Col>
                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={noteocean}
                            title="NoteOcean"
                            description="A web application portal made for multi-user note sharing."
                            link="https://noteocean.ca/"
                        />
                    </Col>
                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={musiq}
                            title="MusiQ"
                            description="An event driven crypto trading application to automate trading based on the said Coinbase-effect it has a tested positive income. With 94% probability of gaining 10%-20% on your investements. Due to the success rate, source code might not be available since I am trying to commercialize it."
                            link="https://github.com/professor-forward/MusiQ"
                        />
                    </Col>
                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={memoar}
                            title="Memoarrr!"
                            description="A basic memory-based card game implemented in C++. Showcases major functionalities of C++."
                            link="https://github.com/ferozalizada/Memoar"
                        />
                    </Col>

                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={random}
                            title="RichMaker"
                            description="An event driven crypto trading application to automate trading based on the said Coinbase-effect it has a tested positive income. With 94% probability of gaining 10%-20% on your investements. Due to the success rate, source code might not be available since I am trying to commercialize it."
                            link="https://github.com/ferozalizada/richmaker"
                        />
                    </Col>

                    <Col
                        md={4}
                        lg={4}
                        sm={6}
                        className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <ProjectCard
                            imgPath={img4}
                            title="Good Food"
                            description="A simple restaurant web application with fully implemented backend and database."
                            link="https://github.com/ferozalizada/GoodFood"
                        />
                    </Col>
                    {/* <Col md={4} lg={4} sm={6} className="project-card px-lg-5 px-md-3 px-sm-4 px-xs-4">
            <ProjectCard
              imgPath={img2}
              title="MusiQ"
              description=""
              link="https://hirefast.ca"
            />
          </Col> */}
                </Row>
            </Container>
        </Container>
    )
}

export default Projects

import React from 'react'
import ReactTypingEffect from 'react-typing-effect'

import Particles from 'react-particles-js'
import particlesConfig from '../config/ParticlesConfig.json'

import { Container, Row, Col } from 'react-bootstrap'
const Home = ({ staticText, inputString }) => {
    return (
        <Container fluid className="home-about-section" id="home">
            <Container>
                <Row className="px-xs-0 px-sm-1 px-md-1 px-lg-3 px-xl-5">
                    <Col
                        md={6}
                        lg={8}
                        className="home-about-description px-lg-5 px-md-3 px-sm-4 px-xs-4"
                    >
                        <Particles params={particlesConfig} />
                        <h1 className="heading mb-5">Hello!</h1>
                        <h1 className="heading-name">
                            I'M
                            <strong className="main-name"> {staticText}</strong>
                        </h1>
                        <ReactTypingEffect
                            id="typingText"
                            typingDelay={2}
                            eraseSpeed={50}
                            eraseDelay={800}
                            text={inputString}
                            className="react-type-writer"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Home

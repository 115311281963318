import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import { ImGithub } from 'react-icons/im'
import Button from 'react-bootstrap/Button'
import {
    AiOutlineHome,
    AiOutlineFundProjectionScreen,
    AiOutlineUser,
} from 'react-icons/ai'
import { CgFileDocument } from 'react-icons/cg'

function NavBar({ gitUrl }) {
    const [expand, updateExpanded] = useState(false)
    const [navColour, updateNavbar] = useState(false)

    function scrollHandler() {
        if (window.scrollY >= 20) {
            updateNavbar(true)
        } else {
            updateNavbar(false)
        }
    }

    window.addEventListener('scroll', scrollHandler)

    return (
        <Navbar
            expanded={expand}
            fixed="top"
            expand="md"
            className={navColour ? 'sticky' : 'navbar'}
        >
            <Container>
                <Navbar.Brand href="/">Fa</Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="responsive-navbar-nav"
                    onClick={() => {
                        updateExpanded(expand ? false : 'expanded')
                    }}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto" defaultActiveKey="#home">
                        <Nav.Item>
                            <a
                                className="nav-link"
                                href="#home"
                                onClick={() => updateExpanded(false)}
                            >
                                <AiOutlineHome
                                    style={{ marginBottom: '2px' }}
                                />{' '}
                                Home
                            </a>
                        </Nav.Item>

                        <Nav.Item>
                            <a
                                className="nav-link"
                                href="#about "
                                onClick={() => updateExpanded(false)}
                            >
                                <AiOutlineUser
                                    style={{ marginBottom: '2px' }}
                                />{' '}
                                About
                            </a>
                        </Nav.Item>

                        <Nav.Item>
                            <a
                                className="nav-link"
                                href="#projects "
                                onClick={() => updateExpanded(false)}
                            >
                                <AiOutlineFundProjectionScreen
                                    style={{ marginBottom: '2px' }}
                                />{' '}
                                Projects
                            </a>
                        </Nav.Item>

                        <Nav.Item>
                            <a
                                className="nav-link"
                                href="https://docs.google.com/document/d/1mpVaksvwkHiNsAHjmD-aH9xcNw2H8ibCurFH9rqQHEQ/edit?usp=sharing"
                                onClick={() => updateExpanded(false)}
                            >
                                <CgFileDocument
                                    style={{ marginBottom: '2px' }}
                                />{' '}
                                Resume
                            </a>
                        </Nav.Item>
                        <Nav.Item className="git-btn">
                            <Button
                                href={gitUrl}
                                target="_blank"
                                className="git-btn-inner"
                            >
                                <ImGithub
                                    className="git-btn-color"
                                    style={{ fontSize: '1.2em' }}
                                />{' '}
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar
